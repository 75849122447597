import { useState, useContext } from "react";
// import axios from "axios";
import { useLocation  , Link} from 'react-router-dom'
import emailjs from "emailjs-com";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { CartContext } from '../product_page/function';
import './Form.css';

function Checkout() {
    const cart = useContext(CartContext);
    const { totalPrice, totalvalue, shippingPrice } = cart;

    const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const titles = queryParams.getAll('titles');
const quantities = queryParams.getAll('quantities');
const displayText = titles
    .map((title, index) => ` ${title}, Qty: ${quantities[index]}`)
    .join('\n');


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [country, setCountry] = useState("Pakistan");
    const [region, setRegion] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [postcode, setPostcode] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    
    
    

    const handleEmail = () => { const errors = {};

    if (!firstName.trim()) {
        errors.firstName = "First Name is required";
    }

    if (!lastName.trim()) {
        errors.lastName = "Last Name is required";
    }

    if (!region.trim()) {
        errors.region = "region is required";
    }

    if (!address.trim()) {
        errors.address = "Street Address is required";
    }

    if (!city.trim()) {
        errors.city = "City is required";
    }

  
    if (!phone.trim()) {
        errors.phone = "Phone number is required";
    } else if (!/^\d{11}$/.test(phone)) {
        errors.phone = "Invalid phone number";
    }

    if (!email.trim()) {
        errors.email = "Email Address is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        errors.email = "Invalid email address";
    }

    setFormErrors(errors);

    // Add this line to prevent placing order if fields are empty
    if (Object.keys(errors).length > 0) {
        return;
    }



    const emailBody = `First Name: ${firstName}\nLast Name: ${lastName}\nCountry: ${country}\nRegion: ${region}\nAddress: ${address}\nAddress2: ${address2}\nPostcode: ${postcode}\nCity: ${city}\nPhone: ${phone}\nEmail: ${email}`;
    const orderSummary = `We received your order! Here is a breakdown::\n ${displayText}\n Total Value: ${totalvalue}`;
    const salesTeamParams = {
        to_email: "sales@malikpharmacy.com", email,
        from_name: "Malik Pharmacy",
        message: emailBody,
        Message: orderSummary,
    };
    
    const userConfirmationParams = {
        to_email: email, 
        from_name: "Malik Pharmacy",
        message: orderSummary,
    };
    emailjs
.send('service_053saxi', 'template_v4bpu4c', userConfirmationParams, '2SU9w8303tVaTvQ30')
.then((response) => {
    
    console.log('User confirmation email sent' ,response );
})
.catch(() => {

    console.log('Failed to send user confirmation email');
});
    emailjs
        .send('service_nzspwz8', 'template_mhrtl9a', salesTeamParams,  '2SU9w8303tVaTvQ30')
        .then(() => {
            setShowAlert(true);
            setFirstName('');
            setLastName('');
            setCompanyName('');
            setCountry('');
            setRegion('');
            setAddress('');
            setAddress2('');
            setPostcode('');
            setCity('');
            setPhone('');
            setEmail('');
            console.log('Email sent:');
        })
        .catch(() => {
            console('Failed to send email:');
        });
};


   




    return (
        <>
            <div>
                <section className='my-5'>
                    {showAlert && (
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            YOUR MESSAGE WAS SENT!
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}></button>
                        </div>
                    )}
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h3>Billing Details                       <Link  to='/'>
                                <button className='btn btn-success show'>Back to Shop</button>
                                </Link></h3>
                               
                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="firstName">First Name *</label>
                                                <input type="text" className={`form-control ${formErrors.firstName && 'is-invalid'}`} id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                                                {formErrors.firstName && <div className="invalid-feedback">{formErrors.firstName}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="lastName">Last Name *</label>
                                                <input type="text" className={`form-control ${formErrors.lastName && 'is-invalid'}`} id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                                                {formErrors.lastName && <div className="invalid-feedback">{formErrors.lastName}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="companyName">Company Name (optional)</label>
                                        <input type="text" className="form-control" id="companyName" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="country">Country / Pakistan Only *</label>
                                        <CountryDropdown className="form-control" id="country" value={country} onChange={(value) => setCountry(value)} disabled />
                                    </div>
                                    {country && (
                                        <div className="form-group">
                                            <label htmlFor="region">State / Province *</label>
                                            <RegionDropdown className="form-control" id="region" country={country} value={region} onChange={(value) => setRegion(value)} required />
                                        </div>
                                    )}
                                    <div className="form-group">
                                        <label htmlFor="address">Street Address *</label>
                                        <input type="text" className={`form-control ${formErrors.address && 'is-invalid'}`} id="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                                        {formErrors.address && <div className="invalid-feedback">{formErrors.address}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address2">Apartment, suite, unit, etc. (optional)</label>
                                        <input type="text" className="form-control" id="address2" value={address2} onChange={(e) => setAddress2(e.target.value)} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="postcode">Postcode / ZIP (optional)</label>
                                        <input type="text" className="form-control" id="postcode" value={postcode} onChange={(e) => setPostcode(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="city">Town / City *</label>
                                        <input type="text" className={`form-control ${formErrors.city && 'is-invalid'}`} id="city" value={city} onChange={(e) => setCity(e.target.value)} required />
                                        {formErrors.city && <div className="invalid-feedback">{formErrors.city}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone *</label>
                                        <input type="tel" className={`form-control ${formErrors.phone && 'is-invalid'}`} id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                                        {formErrors.phone && <div className="invalid-feedback">{formErrors.phone}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email Address *</label>
                                        <input
                                            type="email"
                                            className={`form-control ${formErrors.email && 'is-invalid'}`}
                                            id="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email} 
                                            required
                                        />
                                        {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                                    </div>
                                </form>
                                <form>
                                <button className='btn btn-success' onClick={() => { handleEmail(); }}> Place Order </button>
                                        </form>

                            </div>


                            <div className="col-md-6 ">
                                <div className="form">
                                    <div className="header">
                                        <h3>Product</h3>
                                        <h3>Subtotal</h3>
                                    </div>
                                    <div className="subtotal">
                                        <h4>Subtotal</h4>

                                        <h4>{totalPrice}</h4>
                                    </div>
                                    <div className="subtotal">
                                        <h4>Shipping</h4>
                                        <h4>{shippingPrice}</h4>
                                    </div>
                                    <div className="total">
                                        <h4>Total</h4>
                                        <h4>{totalvalue}</h4>
                                       

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default Checkout;













// let cleanedPhone; 

// if (!phone.trim()) {
//     errors.phone = "Phone is required";
// } else {
//     cleanedPhone = phone.replace(/[^\d]/g, ''); 
//     if (cleanedPhone.length === 11 && cleanedPhone.charAt(0) === '0') {
//         cleanedPhone = cleanedPhone.substring(1); 
//         if (/^\d{10}$/.test(cleanedPhone)) {
           
//         } else {
//             errors.phone = "Please enter a valid 10-digit number after removing the leading 0";
//         }
//     } else if (!/^\d{10}$/.test(cleanedPhone)) {
//         errors.phone = "Please enter your 11-digit or 10-digit number";
//     }
// }



 // const sendMessage = async () => {
    
    
    //     try {
    //         const url = "https://graph.facebook.com/v17.0/122099592362001758/messages";
    //         const accessToken = "EAACylYLuyKUBO3XCEL0lS58jKpnxZAfj69jy4ZCA92k03bvbpnEZC64ElxhamKYC13wB6ZBgV8cBUbq9aIEtbNYMSlqW4A6PTFzyuBlGZCP8KYUVExVcquXrEVFViyFZBrGAmDYr965ARM5htZCCEO5Dp3n1I4S2qjP0aBCLLWG2HKcfN14jA4768Urdtcrx9gheOUWMrZCHrt8LZCgd6ZA7oZD"; 
            
    //         const headers = {
    //             "Authorization": `Bearer ${accessToken}`,
    //             "Content-Type": "application/json",
    //         };
            
    //         const data = {
    //             "messaging_product": "whatsapp",
    //             "to": "92" + cleanedPhone,
    //             "type": "template",
    //             "template": {
    //                 "name": "hello_world",
    //                 "language": {
    //                     "code": "en_US"
    //                 },
                   
// "components": [
//     {
//         "type": "body",
//         "parameters": [
//             {
//                 "type": "text",
//                 "text": displayText 
//             },
//             {
//                 "type": "text",
//                 "text": totalvalue 
//             }
//         ]
//     }
// ]
    //             }
    //         };
            
    //         const response = await axios.post(url, data, { headers });
         
            
    //         if (response.status === 200) {
    //             console.log("WhatsApp message sent successfully. ",);
    //         } else {
    //             console.error("Failed to send WhatsApp message:"); 
    //         }
    //     } catch (error) {
    //         console.error("Error sending WhatsApp message:");
    //     }
    // };
    
    // sendMessage();
    