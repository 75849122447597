import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from './Navbar'
import './Form.css'

export default function Breadcrum(prop) {
    const [navbarExpanded, setNavbarExpanded] = useState(false);

    const handleNavbarToggle = () => {
        setNavbarExpanded(!navbarExpanded);
    };

    return (
        <>
            <div>
                <NavBar handleNavbarToggle={handleNavbarToggle} />
                <section style={{ marginTop: navbarExpanded ? '130px' : '0px' }}>
                    <nav aria-label="breadcrumb" className='bread'>
                        <div className="breadcrumb-row-1">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item  bread-item text-light mx-3">
                                    <h1>Catalogue</h1>
                                </li>
                            </ol>
                        </div>
                        <div className="breadcrumb-row-2">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a className='bread-text mx-3' href="https://malikpharmacy.com/">Home</a>
                                    <i className="fa-solid fa-arrow-right fa-beat bread-text text-white"></i>
                                    <span className='bread-text mx-3'>
                                        <Link to="/" className='decoration mx-3' >Shop</Link>
                                    </span>
                                    <i className="fa-solid fa-arrow-right fa-beat bread-text text-white"></i>
                                    <a className='bread-text mx-3 decoration' href="/checkout">Checkout</a>
                                </li>
                            </ol>
                        </div>
                    </nav>
                </section>

            </div>
        </>
    )
}