import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Images/logo.png';
import { CartContext } from '../product_page/function';

export default function Navbar(prop) {
    const cart = useContext(CartContext);
    const [expanded, setExpanded] = useState(false);

    const handleMouseEnter = () => {
        setExpanded(true);
    };

    const handleMouseLeave = () => {
        setExpanded(false);
    };

    return (
        <>
            <div>
                <section>
                    <div className="navbar navbar-expand-lg navbar-light shadow-sm">
                        <a href="https://malikpharmacy.com/">
                            <img className="navbar-brand mx-3" src={Logo} alt='logo' />
                        </a>
                        <button className="navbar-toggler mx-5" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={prop.handleNavbarToggle}>
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link nav-text" href="https://malikpharmacy.com/" >
                                        Home
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link nav-text" href="https://malikpharmacy.com/timeline.html" >
                                        About
                                    </a>
                                </li>
                                <li className="nav-item dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                    <Link className="nav-link dropdown-toggle dropdown-toggle-no-caret" to="/" id="navbarDropdown" role="button" >
                                        Shop
                                    </Link>
                                    <div className={`dropdown-menu${expanded ? " show" : ""}`} aria-labelledby="navbarDropdown">
                                        <Link className='deco' to='/'>
                                            <li
                                                className="dropdown-item nav-text"
                                                onClick={() => cart.setSelectedCategory('')}
                                            >
                                                All Products
                                            </li>
                                            <li
                                                className="dropdown-item nav-text"
                                                onClick={() => cart.setSelectedCategory('Syrups')}
                                            >
                                                Syrups
                                            </li>
                                            <li
                                                className="dropdown-item nav-text"
                                                onClick={() => cart.setSelectedCategory('Skin Care')}
                                            >
                                                Skin Care
                                            </li>
                                            <li
                                                className="dropdown-item nav-text"
                                                onClick={() => cart.setSelectedCategory('Tablets')}
                                            >
                                                Tablets
                                            </li>
                                            <li
                                                className="dropdown-item nav-text"
                                                onClick={() => cart.setSelectedCategory('Powders')}
                                            >
                                                Powders
                                            </li>
                                            <li
                                                className="dropdown-item nav-text"
                                                onClick={() => cart.setSelectedCategory('Oinments')}
                                            >
                                                Ointments
                                            </li>
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
