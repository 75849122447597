import React, { useState, useContext } from 'react';
import { CartContext } from '../product_page/function';
import { Link } from 'react-router-dom';
import Navbar from '../form/Navbar'
import './page.css'

export default function Breadcrum() {
    const cart = useContext(CartContext);
    const { selectedProduct } = cart;
    const { title } = selectedProduct;
    const [navbarExpanded, setNavbarExpanded] = useState(false);

    const handleNavbarToggle = () => {
        setNavbarExpanded(!navbarExpanded);
    };


    return (
        <>
            <div>
                <Navbar handleNavbarToggle={handleNavbarToggle} />
                <section style={{ marginTop: navbarExpanded ? '220px' : '0px' }}>
                    <nav aria-label="breadcrumb" className='bread'>
                        <div className="breadcrumb-row-1" >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item bread-item text-light mx-3" >
                                    <h1>Catalogue</h1>
                                </li>
                            </ol>
                        </div>
                        <div className="breadcrumb-row-2" >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item ">
                                    <span className='bread-text mx-3'>
                                        <a href="https://malikpharmacy.com/" className='decoration'>Home</a>
                                    </span>
                                    <i className="fa-solid fa-arrow-right fa-beat bread-text text-white" ></i>
                                    <span className='bread-text'>
                                        <Link to="/" className='decoration mx-3'>Shop</Link>
                                    </span>
                                    <i className="fa-solid fa-arrow-right fa-beat bread-text text-white" ></i>
                                    <span className='bread-text mx-3 decoration'>
                                        <span className='decoration'  >{title}</span>
                                    </span>
                                </li>

                            </ol>
                        </div>
                    </nav>
                </section>

            </div>
        </>
    )
}
