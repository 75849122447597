import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProductCard from './all_product/cards';
import Checkout from './form/Form'
import CartProvider from './product_page/function'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import Haralin from './product_page/Haralin_7';
import Haralins from './product_page/Haralin_30';
import Calculus from './product_page/Calculax';
import Gren from './product_page/Green';
import './App.css'
import Anthrax from './product_page/Athrax';
import Greeen from './product_page/Greeen';
import Cholraa from './product_page/Cholra';
import Lexomm from './product_page/lexom';
import Haralinn from './product_page/Haralin_tab';
import Ubton from './product_page/Haralin_ubt';
import Arnicaa from './product_page/Arnica';
import Syrups from './product_page/Syrup';



function App() {
  return (
    <>
      <div className="App">
        <CartProvider  >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<ProductCard />} />
              <Route path="/shop/haralin_7_days" element={<Haralin />} />
              <Route path="/shop/haralin_30_days" element={<Haralins />} />
              <Route path="/shop/calculus" element={<Calculus />} />
              <Route path="/shop/athrax" element={<Anthrax />} />
              <Route path="/shop/green_care" element={<Gren />} />
              <Route path="/shop/green_essence" element={<Greeen />} />
              <Route path="/shop/cholra" element={<Cholraa />} />
              <Route path="/shop/lexom_tab" element={<Lexomm />} />
              <Route path="/shop/haralin_tab" element={<Haralinn />} />
              <Route path="/shop/haralin_ubton" element={<Ubton />} />
              <Route path="/shop/arnica_hair_oil" element={<Arnicaa />} />
              <Route path="/shop/haralin_syrup" element={<Syrups />} />
              <Route path="/checkout" element={<Checkout />} />
            </Routes>
          </BrowserRouter>
        </CartProvider>
      </div>
    </>
  );
}

export default App;
