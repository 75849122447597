import React, { useContext } from 'react';
import { Link, useNavigate   } from 'react-router-dom';
import { CartContext } from '../product_page/function';
import Breadcrum from './breadcrum';
import Footer from './footer';
import Trash from '../Images/Trash.png'
import './product.css';


export default function ProductCards() {

  const cart = useContext(CartContext);
  const { filteredProducts,
    isOpen,
    openFloatCart,
    closeSidebar,
    handleIncrement,
    handleClickAddToCart,
    handleDecrement,
    floatCartItems,
    setFloatCartItems,
    totalPrice,
    handleQuantityChange,
    setSelectedProduct,
    } = cart;


    const navigate = useNavigate();

    const handleCheckout = () => {
      if (floatCartItems.length > 0) {
        // Create an array of objects with title and quantity properties
        const itemsToSend = floatCartItems.map(item => ({
          title: item.title,
          quantity: item.quantity,
        }));
    
        // Navigate to the checkout route and pass itemsToSend as props
        navigate('/checkout', { state: { itemsToSend } });
    
        // Clear the cart after checkout
        // (you can do this after successful checkout in your checkout component)
        // setFloatCartItems([]);
      } else {
        alert('No items in the cart');
      }
    };











  return (
    <>
      <section>
        <Breadcrum />
      </section>
      <div>
        <section>
          <div className="row m-5">
            <div className="col-md-3">
              <div className="list-group shadow m-3">
                <div className="list-group-item item-list">
                  Medicine
                  <hr />
                  <ul className="list-group">
                    <li>
                      <ul className="list-group">
                        <li>
                          <ul className="subcategories list-group">
                            <li
                              className="list-group-item"
                              onClick={() => cart.setSelectedCategory('')}
                            >
                              All Products
                            </li>
                            <li
                              className="list-group-item"
                              onClick={() => cart.setSelectedCategory('Syrups')}
                            >
                              Syrups
                            </li>
                            <li
                              className="list-group-item"
                              onClick={() => cart.setSelectedCategory('Skin Care')}
                            >
                              Skin Care
                            </li>
                            <li
                              className="list-group-item"
                              onClick={() => cart.setSelectedCategory('Tablets')}
                            >
                              Tablets
                            </li>
                            <li
                              className="list-group-item"
                              onClick={() => cart.setSelectedCategory('Powders')}
                            >
                              Powders
                            </li>
                            <li
                              className="list-group-item"
                              onClick={() => cart.setSelectedCategory('Oinments')}
                            >
                              Oinments
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <div className="row">
                {filteredProducts.map((product) => (
                  <div className="col-md-4" key={product.id}>
                    <div className="card shadow m-2">
                      <div className="card shadow">
                        <Link
                          key={product.id}
                          to={product.link}
                          onClick={() => {
                            setSelectedProduct(product);
                          }}
                        >
                          <img className="card-img-top" src={product.image} alt={product.title} />
                        </Link>

                      </div>
                      <div className="card-body">
                        <h5 className="card-title">{product.title}</h5>
                        <p className="card-text">Rs {product.price}</p>

                        <button  
                          className="btn btn-primary" 
                          onClick={() =>
                            handleClickAddToCart(product.image, product.price, product.title, product.shortDescription)
                          }
                        >
                          Add to cart
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div   className="row">
            <div className={`col-md-3 float-cart ${isOpen ? 'float-cart--open' : ''}`}>
              <div className="col-md-12">
                <span
                  onClick={() => {
                    isOpen ? closeSidebar() : openFloatCart();
                  }}
                  className="bag bag--float-cart-closed"
                >
                  <span className="bag__quantity">
                    {floatCartItems.length > 0 && floatCartItems.reduce((total, item) => total + item.quantity, 0)}
                  </span>
                </span>
              </div>
              <div className="col-md-12 float-cart__content">

                <div className="float-cart__header">
                  <div className='svj'>
                    <button type="button" className=" svj btn-close" onClick={() => (isOpen ? closeSidebar() : openFloatCart())} aria-label="Close"></button>
                  </div>
                  <div className="col-md-12 ">
                    <span className="bag">
                    </span>
                    <span className="header-title">Bag</span>
                  </div>
                </div>
                <div className="float-cart__shelf-container">
                  {floatCartItems.map(item => (
                    <div className="shelf-item my-1" key={item.id}>
                      <div className="row">
                        <div className="col-md-4">
                          <img src={item.image} alt='items' className="img-fluid med" />
                        </div>
                        <div className="col-md-4">
                          <h3 className="title">{item.title}</h3>
                          <h5 className='price'>
                            Rs {item.price} X {item.quantity}
                          </h5>
                          <div className="quantity-input my-3 text-center">
                            <button onClick={() => handleDecrement(item)} className="btn btn-warning  btn-sm ">-</button>
                            <input
                              type="number"
                              min="1"
                              className="quantity-input-field"
                              value={item.quantity}
                              onChange={e => handleQuantityChange(e, item)}
                            />
                            <button onClick={() => handleIncrement(item)} className="btn btn-danger  btn-sm">+</button>
                          </div>
                        </div>
                        <div className="col-md-2 m-3">
                          <img src={Trash} alt='trash'
                            className="  cross"
                            onClick={() => {
                              const updatedItems = floatCartItems.filter((cartItem) => cartItem.id !== item.id);
                              setFloatCartItems(updatedItems);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {floatCartItems.length === 0 && (
                    <div className="col-md-12">
                      <h3 className="shelf-empty">
                        Add some products to the bag
                        <br />

                      </h3>
                    </div>
                  )}
                </div>

                <button className="btn btn-dark" onClick={handleCheckout}>
                  Checkout
                </button>

              </div>
              <div className="float-cart__footer">
                <div className="sub color-white">
                  Subtotal: {totalPrice}
                </div>
                <div className='checkout'>

                </div>
              </div>
            </div>
          </div>
        </section >
      </div >
      <section>
        <Footer />
      </section>
    </>
  );
}



// {floatCartItems.length > 0 ? (
//   <Link
//     className='btn btn-dark'
//     to={`/checkout?${floatCartItems.map(item => `titles=${encodeURIComponent(item.title)}&quantities=${item.quantity}`).join('&')}`}
//   >
//     Checkout
//   </Link>

// ) : (
//   <button className="btn btn-dark" onClick={() => alert('No items in the cart')}>
//     Checkout
//   </button>
// )}



     //{<Link to={`/shop/${product.title}?image=${encodeURIComponent(product.image)}&price=${product.price}&title=${product.title}&shortDescription=${product.shortDescription}`}>
       //                   <img className="card-img-top" src={product.image} alt={product.title} />
         //               </Link> 


