import React, { useState } from 'react'
import './product.css'
import Facebook from '../Images/facebook-logo.svg'
import Logo from '../Images/logo.png'
import Instgram from '../Images/instagram-logo.svg'
import emailjs from "emailjs-com";

export default function Footer() {

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [formErrors, setFormErrors] = useState({});

    const handleEmail = () => {
        const errors = {};
        if (!email.trim()) {
            errors.email = "Email Address is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Invalid email address";
        }
        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }
        const emailBody = `Email: ${email}\nMessage: ${message}`;
       
        const salesTeamParams = {
            to_email: "sales@malikpharmacy.com", email,
            from_name: "Malik Pharmacy",
            message: emailBody,
          
        };
   
    emailjs
            .send('service_7x4n3mp', 'template_mhrtl9a', salesTeamParams,  '2SU9w8303tVaTvQ30')
            .then(() => {
                setEmail('');
                console.log('Email sent:');
            })
            .catch(() => {
                console('Failed to send email:');
            });
    };

    return (
        <>
            <div>
                <section>
                    <footer className="bg-dark text-white py-5 fade-in">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3 mb-4">
                                    <img className="navbar-brand mx-3" src={Logo} alt="exaple"
                                    />
                                    <p>Haralin is a homeopathic powder formulated from lactose, commonly known as milk sugar.
                                        The resulting product, Haralin, is a finely powdered substance that can be easily
                                        dissolved in water or other liquids, making it convenient for administration to
                                        children.</p>
                                    <h5 className="cil">Address</h5>
                                    <p>Chak No. 103R.B, 7Km Jaranwala Road. Faisalabad, Punjab Pakistan.</p>

                                </div>
                                <div className="col-md-3 mb-4">
                                    <h5 className="cul">Social Links</h5>
                                    <div className="social-links ">
                                        <a href="https://www.facebook.com/malikpharmacyhomeoreg" target="blank" className=""><img
                                            className="logo mx-2" src={Facebook} alt="facebook" /></a>
                                        <a href="https://www.instagram.com/malikpharmacyhomeo/" target="blank" className=""><img
                                            className="logo mx-2" src={Instgram} alt="instagram" /></a>
                                    </div>
                                    <h5 className="cil my-3">Contact</h5>
                                    <p>0300 | 0333-6642356</p>
                                    <p>malikpharmacyhomeo@gmail.com</p>
                                </div>

                                <div className="col-md-3 mb-4">
                                    <h5 className="cul">Events</h5>
                                    <p>Umrah tour 2022</p>
                                    <p>Annual lucky draw</p>
                                    <p>Tour to muree</p>
                                    <p>Tour to Makkah</p>
                                    <p>Herbal association</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <h5 className="cul">Contact Us</h5>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="email">Email Address *</label>
                                            <input
                                                type="email"
                                                className={`form-control ${formErrors.email && 'is-invalid'}`}
                                                id="email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email}
                                                required
                                            />
                                            {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                                        </div>
                                        <div className="form-group">

                                            <textarea className="form-control mt-2" id="message" name="message"
                                                value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Enter your message"></textarea>
                                        </div>
                                        <button className="btn btn-success" onClick={() => {
                                            handleEmail();
                                            alert('YOUR ORDER WAS PLACED!');
                                        }}>
                                            Place Order
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </footer>
                </section>
            </div>
        </>
    )
}
