import React, { useContext } from 'react';
import { Link, useNavigate   } from 'react-router-dom';
import { CartContext } from './function';
import Green from '../Images/Green-Essendence.webp';
import Footer from '../all_product/footer';
import Breacdcrum from '../all_product/breadcrum'
import './cart.css';
import Trash from '../Images/Trash.png'

export default function Greeen() {
    const cart = useContext(CartContext);
    const {
        isOpen,
        openFloatCart,
        closeSidebar,
        handleIncrement,
        handleClickAddToCart,
        handleDecrement,
        floatCartItems,
        setFloatCartItems,
        handleQuantityChange,
          } = cart;


const image = Green;
const title = "Green Essence";
const price = 1000;

const navigate = useNavigate();

const handleCheckout = () => {
  if (floatCartItems.length > 0) {
    // Create an array of objects with title and quantity properties
    const itemsToSend = floatCartItems.map(item => ({
      title: item.title,
      quantity: item.quantity,
    }));

    // Navigate to the checkout route and pass itemsToSend as props
    navigate('/checkout', { state: { itemsToSend } });

    // Clear the cart after checkout
    // (you can do this after successful checkout in your checkout component)
    // setFloatCartItems([]);
  } else {
    alert('No items in the cart');
  }
};

    return (
        <>
         <section>
              <Breacdcrum />
            </section>
            <section>

                <div className="row m-2">
                    <div className="col-lg-5">
                        <div className="card">
                            <img src={image} alt="example" className="card-img-top hover-zoom" />
                        </div>
                    </div>
                    <div className="col-lg-3  d-flex flex-column justify-content-start">
                        <div className="my-2">
                            <p className="display-4">{title}</p>
                        </div>
                        <div className="my-2">
                            <h3 className="card-price">Rs {price}</h3>
                        </div>
                        <div className="my-2">
                            <p className="img-text">Green Essence a natural water-based formula that nourishes the skin. Green  essence is a skincare marvel designed to transform your daily beauty routine into a revitalizing and hydrating experience.</p>
                        </div>
                        <div className="my-2">
                            <button
                                className="btn btn-success"
                                onClick={() => {
                                    handleClickAddToCart(image, price, title);
                                }}
                            >
                                Add to Cart
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-4 side shadow-sm ">
                        <div className="auth">
                            <h4>DELIVERY</h4>
                            <p>Faisalabad: 1 To 2 Days | Other Cities: 3 To 5 Working Days</p>
                        </div>
                        <div className="auth">
                            <h4>100% ORIGINAL PRODUCTS</h4>
                            <p>
                                100% Original Products That Are High-Grade Products From China Or High-Quality Goods Produced Locally.
                            </p>
                        </div>
                        <div className="auth">
                            <h4>CUSTOMER SATISFACTION</h4>
                            <p>
                                Customer Satisfaction By Providing High-Quality Products, Excellent Customer Service, And A Hassle-Free Shopping Experience.
                            </p>
                        </div>
                        <div className="auth">
                            <h4>SAFE DELIVERY</h4>
                            <p>
                                Our Responsibility For Safe Delivery Of Products By Using Reliable Delivery Partners And Ensuring That Items Are Properly Packed And Secured During Transit To Minimize The Risk Of Damage.
                            </p>
                        </div>
                    </div>
                </div>



                <div  className="row">
                    <div className={`col-md-3 float-cart ${isOpen ? 'float-cart--open' : ''}`}>
                        <div className="col-md-12">
                            <span
                                onClick={() => {
                                    isOpen ? closeSidebar() : openFloatCart();
                                }}
                                className="bag bag--float-cart-closed"
                            >
                                <span className="bag__quantity">
                                    {floatCartItems.length > 0 && floatCartItems.reduce((total, item) => total + item.quantity, 0)}
                                </span>
                            </span>
                        </div>
                        <div className="col-md-12 float-cart__content">
                            <div className="float-cart__header">
                                <div className='svj'>
                                    <button type="button" className=" svj btn-close" onClick={() => (isOpen ? closeSidebar() : openFloatCart())} aria-label="Close"></button>
                                </div>
                                <div className="col-md-12">
                                    <span className="bag">
                                    </span>
                                    <span className="header-title" onClick={() => (isOpen ? closeSidebar() : openFloatCart())}>
                                        Bag
                                    </span>
                                </div>
                            </div>
                            <div className="float-cart__shelf-container col-md-12">
                                {floatCartItems.map(item => (
                                    <div className="shelf-item my-1" key={item.id}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <img src={item.image} alt='items' className="img-fluid med" />
                                            </div>
                                            <div className="col-md-4">
                                                <h3 className="title">{item.title}</h3>
                                                <h5 className='price'>
                                                    Rs {item.price} X {item.quantity}
                                                </h5>

                                                <div className="quantity-input">
                                                    <button onClick={() => handleDecrement(item)} className="btn btn-warning btn-sm">-</button>
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        className="quantity-input-field"
                                                        value={item.quantity}
                                                        onChange={e => handleQuantityChange(e, item)}
                                                    />
                                                    <button onClick={() => handleIncrement(item)} className="btn btn-danger btn-sm">+</button>
                                                </div>
                                            </div>
                                            <div className="col-md-1 ">
                                                <img
                                                    src={Trash}
                                                    alt="trash"
                                                    className="cross"
                                                    onClick={() => {
                                                        const updatedItems = floatCartItems.filter(
                                                            cartItem => cartItem.id !== item.id
                                                        );
                                                        setFloatCartItems(updatedItems);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {floatCartItems.length === 0 && (
                  <div className="col-md-12">
                    <h3 className="shelf-empty">
                      Add some products to the bag
                      <br />

                    </h3>
                  </div>
                )}
                            </div>
                            
                        </div>
                        <div className="float-cart__footer">
                            <div className="sub color-white">Subtotal   {floatCartItems.reduce((total, item) => total + item.quantity * item.price, 0).toFixed(2)}
                                <div> {floatCartItems.length > 0 ? (
                                      <button className="btn btn-success" onClick={handleCheckout}>
                                      Checkout
                                    </button>
                    


                                ) : (
                                    <button className="btn btn-success" onClick={() => alert('No items in the cart')}>
                                        Checkout
                                    </button>

                                )}
                                  <Link  to='/'>
                                <button className='btn btn-success mx-3'>Continue Shopping</button>
                                </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
              <Footer />
            </section>
        </>
    );
}
