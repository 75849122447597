import React, { useState } from 'react'
import './product.css'

import Navbar from '../form/Navbar';



export default function Breadcrum() {
    const [navbarExpanded, setNavbarExpanded] = useState(false);

    const handleNavbarToggle = () => {
        setNavbarExpanded(!navbarExpanded);
    };
    return (
        <>
            <div>
                <Navbar handleNavbarToggle={handleNavbarToggle} />
                <section style={{ marginTop: navbarExpanded ? '130px' : '0px' }}>
                    <nav aria-label="breadcrumb" className='bread' >
                        <div className="breadcrumb-row-1">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item bread-item text-light mx-3">
                                    <h1>Catalogue</h1>
                                </li>
                            </ol>
                        </div>
                        <div className="breadcrumb-row-2">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a className='bread-text mx-3' href="https://malikpharmacy.com/">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <i className="fa-solid fa-arrow-right fa-beat bread-text text-white"></i>
                                    <a className='bread-text mx-3' href="/" style={{ textDecoration: "none" }}>Shop</a>
                                </li>
                            </ol>
                        </div>
                    </nav>
                </section>
            </div>
        </>
    )
}
