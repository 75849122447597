import React, { useState, createContext, useEffect, useMemo ,useRef  } from 'react';
import Cookies from 'js-cookie';
import HaralinP from '../Images/Haralin-Powder-2.webp';
import Arcinca from '../Images/Arcina-hair-oil.webp';
import Atrax from '../Images/Athrax.webp';
import Calculax from '../Images/calculus.webp';
import Green from '../Images/gree-care.webp';
import Essendse from '../Images/Green-Essendence.webp';
import HaralinT from '../Images/Haralin-Tab.webp';
import Mensole from '../Images/Lexom-tab.webp';
import Ubton from '../Images/Ubton.webp'
import Cholra from '../Images/Cholra.webp'
import Dabi from '../Images/Dabi.webp'
import Syrup from '../Images/Syrup.webp'




export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState();
  
  const [selectedProduct, setSelectedProduct] = useState({});
 

  const productArray = useMemo(() => [
    {
     
      id: '1',
      title: 'Haralin 7 Days',
      price: '170',
      image: HaralinP,
      category: 'Powders',
      shortDescription: 'Our specially formulated homeopathic immunity booster powder is designed with your childs well-being in mind.  Crafted from a careful blend of time-tested homeopathic remedies. This powder is here to fortify your childs immune system and nurture their overall health.',
      longDescription: '',
      meta: 'A trusted solution for childrens health challenges. From marasmus and diarrhea to vomiting, dyspepsia, fever, and weakness, Haralin effectively addresses these issues, promoting well-being and vitality in young ones.',
      link: '/shop/haralin_7_days',
    }
    ,
    {
      id: '2',
      title: 'Haralin 30 Days',
      price: '1500',
      image: Dabi,
      category: 'Powders',
      shortDescription: 'Our specially formulated homeopathic immunity booster powder is designed with your childs well-being in mind.  Crafted from a careful blend of time-tested homeopathic remedies. This powder is here to fortify your childs immune system and nurture their overall health.',
      longDescription: '',
      meta: 'A trusted solution for childrens health challenges. From marasmus and diarrhea to vomiting, dyspepsia, fever, and weakness, Haralin effectively addresses these issues, promoting well-being and vitality in young ones.',
    link: '/shop/haralin_30_days'
    },
    {
      id: '3',
      title: 'Calculus',
      price: '300',
      image: Calculax,
      category: 'Syrups',
      shortDescription: 'Removes kidney stones and helps combat infections, promoting improved urinary health and providing relief from associated discomfort.',
      longDescription: '',
      meta: 'Removes kidney stones and helps combat infections, promoting improved urinary health and providing relief from associated discomfort.',
      link: '/shop/calculus'
    },
    {
      id: '4',
      title: 'Athrax',
      price: '350',
      image: Atrax,
      category: 'Tablets',
      shortDescription: 'Athrax powerful remedy for uterine weakness, restoring strength and regeneration. Rediscover wellness and regain confidence with Athrax.',
      longDescription: '',
      meta: 'Athrax: A potent solution for athra and uterine weakness, empowering strength and vitality. Rediscover wellness and regain confidence with Athrax.',
      link: '/shop/athrax'
    },
    {
      id: '5',
      title: 'Green Care',
      price: '200',
      image: Green,
      category: 'Oinments',
      shortDescription: 'Alleviates itching and reduces pustules, providing effective relief for skin irritation and promoting a healthier complexion.',
      longDescription: '',
      meta: 'Alleviates itching and reduces pustules, providing effective relief for skin irritation and promoting a healthier complexion.',
      link: '/shop/green_care'
    },
    {
      id: '6',
      title: 'Green Essence',
      price: '1000',
      image: Essendse,
      category: 'Skin Care',
      shortDescription: 'Green Essence a natural water-based formula that nourishes the skin. Green  essence is a skincare marvel designed to transform your daily beauty routine into a revitalizing and hydrating experience. ',
      longDescription: '',
      meta: 'Green Essence Homeopathic Water for human Beauty, A natural water-based formula that nourishes the skin.',
      link:'/shop/green_essence'
    },
    {
      id: '7',
      title: 'Cholra',
      price: '150',
      image: Cholra,
      category: 'Syrups',
      shortDescription: 'Cholra drops gives relief from diarrehea and upset stomach. Specifically formulated drops to help restore digestive system.',
      longDescription: '',
      meta: 'Cholra drops gives relief from diarrehea and upset stomach. Specifically formulated drops to help restore digestive system',
      link:"/shop/cholra"
    },
    {
      id: '8',
      title: 'Lexom Tab',
      price: '350',
      image: Mensole,
      category: 'Tablets',
      shortDescription: 'Lexom Tab  versatile solution for womens health, addressing antileucorrhea, menstrual disorders, miscarriage, and obesity, promoting overall well-being and vitality.',
      longDescription: '',
      meta: '',
      link:"/shop/lexom_tab"
    },
    {
      id: '9',
      title: 'Haralin Tab',
      price: '350',
      image: HaralinT,
      category: 'Tabblets',
      shortDescription: 'Haralin Tab is advanced form of Haralin Powder specially designed for teenagers and adults for better health development.',
      longDescription: '',
      meta: 'Haralin Tab is designed to alleviate symptoms, cure diseases, or manage chronic conditions. They can target specific ailments, relieve pain, reduce inflammation, fight infections',
      link:"/shop/haralin_tab"
    },
    {
      id: '10',
      title: 'Haralin Ubton',
      price: '250',
      image: Ubton,
      category: 'Skin Care',
      shortDescription: 'Haralin Ubton  skincare solution that nourishes the skin while effectively clearing blemishes, promoting a radiant and flawless appearance. Experience the transformative power of Haralin Ubton for a revitalized complexion.',
      longDescription: '',
      meta: 'Haralin Ubton: A skincare solution that nourishes the skin while effectively clearing blemishes, promoting a radiant and flawless appearance. Experience the transformative power of Haralin Ubton for a revitalized complexion.',
      link:"/shop/haralin_ubton"
    },
    {
      id: '11',
      price: '1200',
      title: 'Arnica',
      image: Arcinca,
      category: 'Oinments',
      shortDescription: 'Arnica Hair Oil strengthens hair, promotes healthy growth, reduces hairfall, nourishes for shinny and nourished hair.',
      longDescription: '',
      meta: 'Arnica Hair Oil strengthens hair, promotes healthy growth, reduces hairfall, and nourishes for shinny, nourished hair.',
      link:"/shop/arnica_hair_oil"
    },
    {
      id: '12',
      title: 'Haralin Syrup',
      price: '200', 
      image: Syrup,
      category: 'Syrups',
      shortDescription: 'Advance form of Haralin powder in syrup form formulated for healthy digestive immune system.',
      longDescription: '',
      meta: 'Haralins lactose-based formula offers a convenient and natural way to obtain crucial carbohydrates and proteins.',
      link:"/shop/haralin_syrup"
    },
  ], []);


  const [cartItems, setCartItems] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const defaultFloatCartItems = () => {
    const savedCartItems = Cookies.get('cartItems');
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  };

  const [floatCartItems, setFloatCartItems] = useState(defaultFloatCartItems);

  useEffect(() => {
    Cookies.set('cartItems', JSON.stringify(floatCartItems), { expires: 7 });
  }, [floatCartItems]);

  const cartRef = useRef(null)
  const openFloatCart = () => {
    setIsOpen(true);
  localStorage.setItem('sidebarIsOpen', 'true');
  };
  
  const closeSidebar = () => {
    setIsOpen(false);
    localStorage.setItem('sidebarIsOpen', 'false');
  };
    
  useEffect(() => {
  
    const handleOutsideClick = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        closeSidebar();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  
 

  const handleClickAddToCart = (image, price, title) => {
    if (image && price && title) {
      const existingItem = floatCartItems.find(item => item.title === title);
  
      if (existingItem) {
        const updatedItems = floatCartItems.map(item => {
          if (item.title === title) {
            return {
              ...item,
              quantity: item.quantity + 1
            };
          }
          return item;
        });
        setFloatCartItems(updatedItems);
      } else {
        const newItem = {
          id: title, 
          title: title,
          image: image,
          price: price,
          quantity: 1,
        };
        setFloatCartItems([...floatCartItems, newItem]);
        localStorage.setItem('floatCartItems', JSON.stringify([...floatCartItems, newItem]));

      }
    }
    setIsOpen(true);
  };
  
  const handleQuantityChange = (e, item) => {
    const quantity = parseInt(e.target.value);
    if (!isNaN(quantity) && quantity >= 0) {
      const updatedItems = floatCartItems.map(cartItem =>
        cartItem.id === item.id ? { ...cartItem, quantity } : cartItem
      );
      setFloatCartItems(updatedItems);
    }
  };

  const handleIncrement = (product) => {
    const updatedItems = floatCartItems.map((item) =>
      item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
    );
    setFloatCartItems(updatedItems);
  };

  const handleDecrement = (product) => {
    const updatedItems = floatCartItems.map((item) =>
      item.id === product.id ? { ...item, quantity: Math.max(item.quantity - 1, 1) } : item
    );
    setFloatCartItems(updatedItems);
  };

  const filteredProducts = selectedCategory
  ? productArray.filter((product) => product.category === selectedCategory)
  : productArray;

const calculateTotalCost = () => {
  return floatCartItems.reduce((total, item) => total + item.quantity * item.price, 0);
};

const totalPrice = calculateTotalCost();
const shippingPrice = 250;
const Total = () => {
  return totalPrice + shippingPrice;
};

const totalvalue = Total();





    const cart = {
    cartItems,
    productArray,
    selectedCategory,
    setSelectedCategory,
    setCartItems,
    filteredProducts,
    isOpen,
    openFloatCart,
    closeSidebar,
    handleIncrement,
    handleClickAddToCart,
    handleDecrement,
    floatCartItems,
    setFloatCartItems,
    totalPrice,
    Total,
    totalvalue,
    shippingPrice,
    handleQuantityChange,
    selectedProduct,
    setSelectedProduct,
    cartRef
  
  };


  return (
    <CartContext.Provider value={cart}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
