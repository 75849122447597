import React from 'react'
import Breadcrumb from './Breacdcrumb'
import Checkout from './Checkout'
import './Form.css'

export default function Form() {
  return (
    <>
    <div>
      <section>
        <Breadcrumb />
      </section>
      <section>
        <Checkout />
      </section> 
    </div>
    </>
  )
}
